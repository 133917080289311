import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useJobCommentList(jobId) {
  // Use toast
  const refJobCommentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, },
    { key: 'commentator', label: 'User', sortable: true },
    { key: 'comment', sortable: false, thClass: 'w-50', tdClass: 'w-50' },
    { key: 'actions', sortable: false }
  ]
  const perPage = ref(10)
  const totalJobComments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rows = ref([])
  const search = ref('')
  // const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refJobCommentListTable.value ? refJobCommentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalJobComments.value,
    }
  })

  const refetchData = async () => {
    await GetData()
  }

  function GetJobComments() {
    return new Promise((resolve, reject) => {
      store.dispatch('pamis-job/fetchJobComments', jobId.value)
        .then(response => {
          if (response.data != null) {
            rows.value = response.data
            rows.value.forEach(e => {
              e.comment = e.content
              e.commentator = `${e.user.name} ${e.user.surName}`
            })
            rows.value = rows.value.reverse();
            resolve(response.data);
          }
          else if (response.data.length === 0) {
            rows.value = [];
          }
        }).catch(e => reject(e))
    })
  }
  async function GetData() {
    await GetJobComments()
  }
  GetData()
  return {
    rows,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalJobComments,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refJobCommentListTable,
    refetchData,
  }
}
